import React, { useEffect, useState } from 'react'
import { Box, Button, Stack, Typography } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import { Link, useSearchParams } from 'react-router-dom'
import CookieGroup from './CookieGroup'
import {
  expiresDate,
  updateAnalyticsCookies,
  updateFunctionalityCookies,
  updateMarketingCookies
} from '../../utils/cookies'
import { useCookies } from 'react-cookie'
import essentialCookies from './data/essentialCookies.json'
import preferenceCookies from './data/preferenceCookies.json'
import analysisCookies from './data/analysisCookies.json'
import marketingCookies from './data/marketingCookies.json'

const CookieSettings = () => {
  const [cookies, setCookie, removeCookie] = useCookies()
  const [disableButton, setDisableButton] = useState(true)
  const [selectedCookies, setSelectedCookies] = useState({
    analysis: cookies.analysisCookies ?? false,
    marketing: cookies.marketingCookies ?? false,
    functionality: cookies.functionalityCookies ?? false
  })
  const [searchParams, setSearchParams] = useSearchParams()

  const cookiesConfig = [
    {
      key: 'gtm',
      title: 'cookieSettings.gtm.title',
      description: 'cookieSettings.gtm.description',
      locked: true,
      updateFn: () => {}
    },
    {
      key: 'essential',
      title: 'cookieSettings.essentialCookies.title',
      description: 'cookieSettings.essentialCookies.description',
      locked: true,
      updateFn: () => {},
      cookiesList: essentialCookies
    },
    {
      key: 'functionality',
      title: 'cookieSettings.preferenceCookies.title',
      description: 'cookieSettings.preferenceCookies.description',
      updateFn: () => updateFunctionalityCookies(selectedCookies.functionality ? 'granted' : 'denied', removeCookie),
      cookiesList: preferenceCookies
    },
    {
      key: 'analysis',
      title: 'cookieSettings.analysisCookies.title',
      description: 'cookieSettings.analysisCookies.description',
      updateFn: () => updateAnalyticsCookies(selectedCookies.analysis ? 'granted' : 'denied', removeCookie),
      cookiesList: analysisCookies
    },
    {
      key: 'marketing',
      title: 'cookieSettings.marketingCookies.title',
      description: 'cookieSettings.marketingCookies.description',
      updateFn: () => updateMarketingCookies(selectedCookies.marketing ? 'granted' : 'denied', removeCookie),
      cookiesList: marketingCookies
    }
  ]

  useEffect(() => {
    if (searchParams.has('reloaded')) {
      searchParams.delete('reloaded')
      setSearchParams()
      renderConfirmationMessage()
    }
  }, [])

  const renderConfirmationMessage = () =>
    window.dispatchEvent(
      new CustomEvent('sendNotification', {
        detail: { type: 'success', message: <FormattedMessage id='notification.changesSaved' /> }
      })
    )

  const handleChangeButton = (cookieKey: string) => {
    setDisableButton(false)
    setSelectedCookies((prevState) => ({
      ...prevState,
      [cookieKey]: !prevState[cookieKey as keyof typeof selectedCookies]
    }))
  }

  const handleConfirmChanges = () => {
    setCookie('hideCookiesBanner', true, { path: '/', expires: expiresDate })
    setDisableButton(true)

    cookiesConfig.forEach(({ key, updateFn }) => {
      const currentCookieValue = cookies[`${key}Cookies`]
      const newValue = selectedCookies[key as keyof typeof selectedCookies]

      // Only update cookie if value has changed
      if (currentCookieValue !== newValue) {
        setCookie(`${key}Cookies`, newValue, { path: '/', expires: expiresDate })
        if (updateFn) {
          updateFn()
        }
      }
    })
    renderConfirmationMessage()
  }

  return (
    <Box>
      <Typography gutterBottom>
        <FormattedMessage
          id='cookieSettings.description'
          values={{
            privacyPolicy: (
              <Link to='/legal/privacy' target='_blank' rel='noopener noreferrer'>
                <FormattedMessage id='legal.privacy' />
              </Link>
            ),
            cookiePolicy: (
              <Link to='/legal/cookies' target='_blank' rel='noopener noreferrer'>
                <FormattedMessage id='legal.cookies' />
              </Link>
            )
          }}
        />
      </Typography>
      <Stack gap={4}>
        {cookiesConfig.map(({ title, description, locked, key, cookiesList }) => (
          <React.Fragment key={key}>
            <CookieGroup
              title={title}
              description={description}
              locked={locked}
              handleChange={() => handleChangeButton(key)}
              checkboxValue={cookies[`${key}Cookies`]}
              cookiesList={cookiesList}
            />
          </React.Fragment>
        ))}
      </Stack>

      <Stack sx={{ alignItems: { md: 'end' }, mt: 4 }}>
        <Button disabled={disableButton} variant='contained' onClick={handleConfirmChanges}>
          <FormattedMessage id='cookies.confirmSelection' />
        </Button>
      </Stack>
    </Box>
  )
}

export default CookieSettings
